<template>
  <div v-if="isDataReady" class="recognition-or" :class="{ 'is-edit': isEdit }">
    <VideoCanvasOr class="container-canvas" />
    <SettingOr class="container-setting" />
    <Subscribers 
      class="container-subscriber" 
      :isEdit="isEdit" 
      :disabled="isEditRoi"
      :subscribers="subscribers" 
      @change="onChangeSubscribers"
    />
    <div v-if="isEdit" class="container-roi">
      <SetRoiArea />
    </div>
    
    <Note class="container-note" v-model="currentNote" :isEdit="isEdit" :disabled="isEditRoi" />
    <Editor class="container-editor" v-if="!isEdit" :userId="editorUserId" />
    <div v-else class="container-editor">
      <div  class="actions" :class="{ disabled: isEditRoi }">
        <div class="btn cancel" @click="cancelTaskEdit">{{ $t('cancel')/*取消*/ }}</div>
        <div class="btn confirm" :class="{ disabled: !isModified }" @click="handleTaskEdit">{{ $t('confirm')/*確認*/ }}</div>
      </div>
    </div>
  </div>
  <div v-else class="recognition-or">
    <Loading :active.sync="isLoading"
      color="#FFC600"
      background="transparent"
      :width="80"
      :height="80"
      :opacity="0.1"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage">
    </Loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { apiGetUserByUid } from '@/api/index.js'

export default {
  name: 'RecognitionOr',
  components: {
    Loading,
    // VideoRoi: () => import('./base/VideoRoi.vue'),
    VideoCanvasOr: () => import('@/components/AiBox/base/VideoCanvasOr.vue'),
    // LprSetting: () => import('@/components/AiBox/base/LprSetting.vue'),
    SettingOr: () => import('@/components/AiBox/base/SettingOr.vue'),
    // Tags: () => import('@/components/AiBox/base/Tags.vue'),
    Subscribers: () => import('@/components/AiBox/base/Subscribers.vue'),
    SetRoiArea: () => import('@/components/AiBox/base/SetRoiArea.vue'),
    Note: () => import('@/components/AiBox/base/Note.vue'),
    Editor: () => import('@/components/AiBox/base/Editor.vue'),
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
    }
  },
  computed: {
    ...mapState('account', ['groupList']),
    ...mapState('aibox', [
      'aiRunOn',
      'aiMode',
      'isEdit',
      'isEditRoi',
    ]),
    ...mapState('recgOr', [
      'currDefaultSetting',
      'note',
    ]),
    ...mapGetters('recgOr', [
      'userId', 
      'userIndex',
      'deviceModelId',
      'currentSetting',
      'isAiCam',
      'isSettingReady',
      'isSettingSrcModified',
      'isSettingModified',
      'isNoteModified',
      'isSubscribersModified',
      'isNotifyFilterModeModified',
    ]),
    ...mapGetters('aibox', [
      'aiBoxTask',
    ]),
    isDataReady() {
      return this.aiRunOn === 'device' && this.isSettingReady || 
        this.aiRunOn === 'aibox' && this.aiBoxTask
    },
    canSetting() {
      // defaultSetting = 0: userSetting 設備客製/可編輯, 1: defaultSetting 系統預設/不可編輯
      return this.currDefaultSetting === 0
    },
    // currentSubscribers: {
    //   get() {
    //     return this.subscribers
    //   },
    //   set(val) {
    //     this.updateSubscribers(val)
    //   },
    // },
    subscribers() {
      if (this.aiRunOn === 'device') return this.$store.state.recgOr.subscribers
      else return this.aiBoxTask?.subscribers
    },
    currentNote: {
      get() {
        if (this.aiRunOn === 'device') return this.note
        else return this.aiBoxTask?.notice
      },
      set(val) {
        if (this.aiRunOn === 'device')
          this.$store.commit('recgOr/updateNote', val)
        else
          this.$store.commit('aibox/updateNotice', val)
      },
    },
    editorUserId() {
      if (this.aiRunOn === 'device') return this.currentSetting.editorUserId
      else return this.aiBoxTask.editorUserId
    },
    isModified() {
      if (this.aiRunOn === 'device')
        return this.isSettingSrcModified || this.isSettingModified || 
          this.isNoteModified || this.isSubscribersModified ||
          this.isNotifyFilterModeModified
      else
        return this.$store.getters['aibox/isModified']
    },
  },
  mounted() {
    if (this.aiRunOn === 'device') this.onInitDeviceOr()  
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapMutations('aibox', [ 
      'updateIsEdit',
      'updateParamAiboxPortal', 
      'updateShowAiboxPortal',
    ]),
    ...mapMutations('recgOr', [
      'resetState',
    ]),
    ...mapActions('recgOr', [
      'getTagList',
      'getDefaultSetting',
      'getUserSetting',
      'getUserInfo',
      'getUserSubscribers',
      'initSetting',
      'editUser',
      'editUserSetting',
      'handleUserSubscribers',
      'onUpdateCurrDefaultSetting',
    ]),
    ...mapActions('aibox', [
      'addOrUpdateAiBoxTask',
      'editSubscribers',
      'getAiBoxTasks',
    ]),
    async onInitDeviceOr() {
      try {
        this.isLoading = true
        this.getTagList()
        this.getUserSubscribers()
        await this.getUserInfo() // get defaultSetting, orNote
        await this.getDefaultSetting()
        await this.getUserSetting()
        
        // -------
        // defaultSetting = 0: userSetting 設備客製/可編輯, 
        // defaultSetting = 1: defaultSetting 系統預設/不可編輯
        // 若為AiCam，永遠只能是設備客製
        if (this.isAiCam) {
          if (this.currDefaultSetting === 1) {
            this.onUpdateCurrDefaultSetting(0)
            await this.editUser() // 參數來源, orNote
          }
        }
        // -------

        this.initSetting()
      } catch (err) {
        console.log('err = ', err)
      } finally {
        this.isLoading = false
      }
    },
    async getUserByUid() {
      const res = await apiGetUserByUid(this.userId)
      console.log('res = ', res)
    },
    cancelTaskEdit() {
      if (!this.isModified) {
        this.updateIsEdit(false)
        return
      }
      // 出現cancelEdit跳窗詢問，確認後的動作為切換模式到檢視模式
      const action = this.aiRunOn === 'device' ? 'cancelDeviceOrEdit' : 'cancelAiboxOrEdit'
      this.updateParamAiboxPortal({ info: 'cancelEdit', action: action })
      this.updateShowAiboxPortal(true)
    },
    async handleTaskEdit() {
      if (this.aiRunOn === 'device') {
        // 'isSettingSrcModified',
        // 'isSettingModified',
        // 'isNoteModified',
        // 'isSubscribersModified',
        await this.editUserSetting()
        await this.handleUserSubscribers()
        await this.editUser() // 參數來源, orNote
      } 
      else {
        // this.keepEditUsers()
        await this.addOrUpdateAiBoxTask()
        await this.editSubscribers()
        // 重新取得AiBoxTasks
        await this.getAiBoxTasks()
      }
      this.updateIsEdit(false)
    },
    onCancel() {},
    onChangeSubscribers(data) {
      if (this.aiRunOn === 'device') {
        const modelMap = {
          lpr: 1,
          or: 2,
          fr: 3,
        }
        const newUsers = data.map(node => ({
          userId: this.userIndex,
          subscriberId: node.index,
          model: modelMap[this.aiMode],
        }))
        this.$store.commit('recgOr/updateSubscribers', newUsers)
      } 
      else {
        const newUsers = data.map(node => {
          const groupName = this.groupList.find(group => group.id === node.groupId).name
          return {
            groupId: node.groupId,
            groupName: groupName || '',
            userAccount: node.id,
            userId: node.index,
            userName: node.name,
          }
        })
        this.$store.commit('aibox/updateSubscribers', newUsers)
      } 
    }
  }
}
</script>
  
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.recognition-or {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 68% 32%;
  grid-template-rows: 55% 23% 19%;
  grid-template-areas: 
    "canvas setting"
    "subscriber note"
    "subscriber editor";
  grid-gap: 12px;
  // padding: 16px 24px 16px 16px;
  background: #282942;
  position: relative;
  &.is-edit {
    grid-template-rows: 50% 5% 15% 16% 7%;
    grid-template-areas: 
      "canvas setting"
      "canvas setting"
      "subscriber roi"
      "subscriber note"
      "subscriber editor";
  }
}

.container-canvas {
  grid-area: canvas;
}

.container-setting {
  grid-area: setting;
}

// .container-tags {
//   grid-area: tags;
//   background: #39425D;
//   border-radius: 8px;
// }

.container-subscriber {
  grid-area: subscriber;
}

.container-roi {
  grid-area: roi;
}

.container-note {
  grid-area: note;
  .wrap-edit-roi {
    display: flex;
    align-items: center;
    column-gap: 12px;
    // margin-bottom: 12px;
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  .edit-roi {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    padding: 4px 12px;
    background: #4A5C78;
    border-radius: 8px;
    cursor: pointer;
    
    img {
      margin-right: 8px;
    }
  }
  .reset-roi {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: #4A5C7880;
    cursor: pointer;
    &:hover {
      background: #4A5C78;
    }
  }
}

.container-editor {
  grid-area: editor;
}

.actions {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  font-size: 16px;
  padding: 0px 12px;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  .btn {
    flex: 1;
    max-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #282942;
    border-radius: 9px;
    text-align: center;
    // padding-top: 8px;
    // padding-bottom: 8px;
    cursor: pointer;
    &:hover {
      background: #FFB703;
    }
    &.cancel {
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 9px;
      text-align: center;
      margin-right: 24px;
      padding-top: 8px;
      padding-bottom: 8px;
      cursor: pointer;
      &:hover {
        background: #ffffff33;
      }
    }
    &.confirm {
      background: #FFC600;
      color: #282942;
      border-radius: 9px;
      text-align: center;
      padding-top: 8px;
      padding-bottom: 8px;
      cursor: pointer;
      &:hover {
        background: #FFB703;
      }
    }
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
</style>